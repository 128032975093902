<template>
  <div>
    <v-dialog
      v-model="dialog"
      persistent
      max-width="700px"
    >
      <v-card>
        <v-card-title>
          <span class="headline">Add Availability</span>
        </v-card-title>
        <v-card-text>
          <v-form
            ref="form"
            v-model="validForm"
          >
          <v-date-picker
            v-model="form.collection"
            first-day-of-week="1"
            header-color="primary"
            full-width
            landscape
            range
          />

          <v-row>
            <v-col>
              <TimeSelectorField
                v-model="form.collect_from_time"
                label="Collect From Time"
              />
            </v-col>
            <v-col>
              <TimeSelectorField
                v-model="form.collect_until_time"
                label="Collect Until Time"
              />
            </v-col>
          </v-row>

          <v-date-picker
            v-model="form.return"
            first-day-of-week="1"
            header-color="primary"
            full-width
            landscape
            range
          />

          <v-row>
            <v-col>
              <TimeSelectorField
                v-model="form.return_from_time"
                label="Return From Time"
              />
            </v-col>
            <v-col>
              <TimeSelectorField
                v-model="form.return_until_time"
                label="Return Until Time"
              />
            </v-col>
          </v-row>

          <ApiAutoCompleteField
            v-model="form.location"
            label="Location"
            path="locations"
          />

          <ApiAutoCompleteField
            v-model="form.vehicle"
            label="Vehicle"
            path="vehicles"
          />

          <v-text-field
            v-model="form.starts_at"
            :rules="[v => !!v || 'Starts at is required']"
            label="Starts At"
            type="datetime-local"
            outlined
            required
            dense
          />

          <v-row>
            <v-col>
              <v-text-field
                  v-model="form.repeat_every_value"
                  label="Repeat Every"
                  type="number"
                  outlined
                  dense
              />
            </v-col>
            <v-col>
              <v-select
                v-model="form.repeat_every_unit"
                :items="['Days', 'Weeks', 'Months']"
                label="Repeat Every"
                outlined
                dense
              />
            </v-col>
          </v-row>

        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="darken-1" text @click="closeDialog">Close</v-btn>
        <v-btn color="primary" text @click="createAvailability">Add Availability <v-icon small right>mdi-plus-circle-outline</v-icon></v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</div>
</template>
<script type="text/javascript">
import HasDialog from '@/mixins/HasDialog'
import HasForm from '@/mixins/HasForm'
import TimeSelectorField from '@/components/TimeSelectorField'
import ApiAutoCompleteField from '@/components/ApiAutoCompleteField'

export default {
  name: 'AddAvailabilityDialog',

  components: {
    TimeSelectorField,
    ApiAutoCompleteField
  },

  mixins: [ HasDialog, HasForm ],

  data () {
    return {
      form: {
        starts_at: this.$moment().format('YYYY-MM-DDTHH:mm'),
        repeat_every_unit: 'Days',
        location: {},
        vehicle: {},
        collect_from_time: '09:00',
        collect_until_time: '18:00',
        return_from_time: '09:00',
        return_until_time: '18:00',
        collection: [],
        return: []
      }
    }
  },

  computed: {
    collectionDateFirst () {
      return this.$moment(this.form.collection[0])
    },

    collectionDateSecond () {
      return this.$moment(this.form.collection[1])
    },

    returnDateFirst () {
      return this.$moment(this.form.return[0])
    },

    returnDateSecond () {
      return this.$moment(this.form.return[1])
    },

    collectFromDateTime () {
      return this.calculateUntilDateTimes(
        this.form.collection,
        this.collectionDateSecond,
        this.collectionDateFirst,
        this.form.collect_from_time
      )
    },

    collectUntilDateTime () {
      return this.calculateFromDateTimes(
        this.form.collection,
        this.collectionDateFirst,
        this.collectionDateSecond,
        this.form.collect_until_time
      )
    },

    returnFromDateTime () {
      return this.calculateUntilDateTimes(
        this.form.return,
        this.returnDateSecond,
        this.returnDateFirst,
        this.form.collect_from_time
      )
    },

    returnUntilDateTime () {
      return this.calculateFromDateTimes(
        this.form.return,
        this.returnDateFirst,
        this.returnDateSecond,
        this.form.collect_until_time
      )
    }
  },

  methods: {
    calculateFromDateTimes (type, dateFirst, dateSecond, time) {
      if (type[0] && type[1]) {
        if (dateFirst > dateSecond) {
          return dateFirst.format('YYYY-MM-DD') + ' ' + time + ':00'
        } else {
          return dateSecond.format('YYYY-MM-DD') + ' ' + time + ':00'
        }
      }
      return null
    },

    calculateUntilDateTimes (type, dateFirst, dateSecond, time) {
      if (type[0] && type[1]) {
        if (dateFirst < dateSecond) {
          return dateFirst.format('YYYY-MM-DD') + ' ' + time + ':00'
        } else {
          return dateSecond.format('YYYY-MM-DD') + ' ' + time + ':00'
        }
      }
      return null
    },

    getLocations (search) {
      this.$api.get({
        path: 'locations',
        params: {
          search: search
        }
      })
        .then(({ data: locations }) => {
          this.locations = locations
        })
    },

    closeDialog () {
      if (this.$refs.form) {
        // this.$refs.form.reset()
        this.$refs.form.resetValidation()
      }
      this.form = {
        ...this.form,
        ...{
          starts_at: this.$moment().format('YYYY-MM-DDTHH:mm'),
          location: {},
          vehicle: {},
          collect_from_time: '09:00',
          collect_until_time: '18:00',
          return_from_time: '09:00',
          return_until_time: '18:00',
          collection: [],
          return: [],
          repeat_every_value: null,
          repeat_every_unit: 'Days'
        }
      }
      this.$emit('update:dialog', false)
    },

    createAvailability () {
      this.$api.persist('post', {
        path: 'availabilities',
        object: {
          repeat_every_value: this.form.repeat_every_value === 0 ? null : this.form.repeat_every_value,
          repeat_every_unit: this.form.repeat_every_value === 0 ? null : this.form.repeat_every_unit,
          location_id: this.form.location.id,
          vehicle_id: this.form.vehicle.id,
          collection_from: this.collectFromDateTime,
          collection_until: this.collectUntilDateTime,
          return_from: this.returnFromDateTime,
          return_until: this.returnUntilDateTime,
          services: [],
          starts_at: this.$momentDateTime(this.form.starts_at),
          ends_at: null,
          type: 'TYPE_AVAILABILITY'
        }
      })
    }
  }
}
</script>
