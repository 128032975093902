<template>
  <div>
    <v-dialog
      v-model="dialog"
      persistent
      max-width="700px"
    >
      <v-card>
        <v-card-title>
          <span class="headline">{{ availability.name }}</span>
        </v-card-title>
        <v-card-text>
          <v-form
            ref="form"
            v-model="validForm"
          >
            <FormErrors ref="formErrors" />

            <v-btn
              class="mr-2"
              text
              @click="closeDialog"
            >
              Close
            </v-btn>
            <v-btn
              color="error"
              outlined
              @click="cancelAvailability"
            >
              Cancel {{ availability.type === 'TYPE_UNAVAILABLE' ? 'Un-Availability' : 'Availability' }}
            </v-btn>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>

    <SnackBar success ref="successBar">
      Availability updated successfully!
    </SnackBar>
    <SnackBar error ref="errorBar">
      There was an issue updating the availability.
    </SnackBar>
  </div>
</template>
<script type="text/javascript">
import HasDialog from '@/mixins/HasDialog'
import HasForm from '@/mixins/HasForm'
import SnackBar from '@/components/SnackBar'
import FormErrors from '@/components/FormErrors'

export default {
  name: 'EditAvailabilityDialog',

  mixins: [ HasDialog, HasForm ],

  components: {
    SnackBar,
    FormErrors
  },

  data () {
    return {
      loading: false
    }
  },

  props: {
    availability: {
      type: Object,
      required: true
    },

    date: {
      type: Object,
      required: true
    }
  },

  methods: {
    cancelAvailability () {
      if (this.availability.type === 'TYPE_UNAVAILABLE') {
        this.deleteAvailability()
      } else {
        this.loading = true
        var isCollection = this.availability.category === 'collection_dates'
        this.$api.persist('post', {
          path: 'availabilities',
          object: {
            repeat_every_value: null,
            repeat_every_unit: null,
            location_id: this.availability.location.id,
            vehicle_id: this.availability.vehicle.id,
            collection_from: isCollection ? this.$momentDateTime(this.$moment(this.date.date)) : null,
            collection_until: isCollection ? this.$momentDateTime(this.$moment(this.date.date)) : null,
            return_from: isCollection ? null : this.$momentDateTime(this.$moment(this.date.date)),
            return_until: isCollection ? null : this.$momentDateTime(this.$moment(this.date.date)),
            services: [],
            starts_at: this.$momentDateTime(),
            ends_at: null,
            type: 'TYPE_UNAVAILABLE'
          }
        })
         .then(() => {
            this.$refs.successBar.open()
            this.closeDialog()
          })
          .catch(error => {
            this.$refs.formErrors.setErrors(error)
            this.$refs.errorBar.open()
          })
          .finally(() => { this.loading = false })
      }
    },

    deleteAvailability () {
      this.$api.delete({
        path: `availabilities/${this.availability.id}`
      })
    }
  }
}
</script>
