<template>
  <div>
    <v-dialog
      ref="dialog"
      v-model="dialog"
      persistent
      width="290px"
    >
      <template v-slot:activator="{ on }">
        <v-text-field
          v-model="localValue"
          :label="label"
          readonly
          v-on="on"
        ></v-text-field>
      </template>
      <v-time-picker
        v-if="dialog"
        v-model="localValue"
        full-width
      >
        <v-spacer></v-spacer>
        <v-btn text color="primary" @click="dialog = false">Cancel</v-btn>
        <v-btn text color="primary" @click="$refs.dialog.save(localValue)">OK</v-btn>
      </v-time-picker>
    </v-dialog>
  </div>
</template>
<script type="text/javascript">
export default {
  name: 'TimeSelectorField',

  props: {
    label: {
      required: false,
      default: () => { return null }
    },
    value: {
      required: true
    }
  },

  data () {
    return {
      dialog: false
    }
  },

  computed: {
    localValue: {
      get () {
        return this.value
      },
      set (localValue) {
        this.$emit('input', localValue)
      }
    }
  }
}
</script>
