<template>
  <div>
    <v-btn
      class="mt-4"
      color="primary"
      @click="showAddDialog = true"
    >
      Add Availability
      <v-icon
        small
        right
      >
        mdi-plus-circle-outline
      </v-icon>
    </v-btn>

    <v-btn
      icon
      class="ma-2"
      @click="$refs.calendar.prev()"
    >
      <v-icon>mdi-chevron-left</v-icon>
    </v-btn>

    <v-btn
      icon
      class="ma-2"
      @click="$refs.calendar.next()"
    >
      <v-icon>mdi-chevron-right</v-icon>
    </v-btn>

    <v-calendar
      v-model="calendar"
      :event-height="40"
      :events="newDates"
      :event-overlap-threshold="30"
      :event-color="eventColor"
      :weekdays="[1, 2, 3, 4, 5, 6, 0]"
      ref="calendar"
      @click:event="handleEventClick"
    />

    <AddAvailabilityDialog
      :dialog.sync="showAddDialog"
    />

    <EditAvailabilityDialog
      :availability="editAvailability"
      :date="editDate"
      :dialog.sync="showEditDialog"
    />

  </div>
</template>
<script type="text/javascript">
import AddAvailabilityDialog from '@/components/availabilities/AddAvailabilityDialog'
import EditAvailabilityDialog from '@/components/availabilities/EditAvailabilityDialog'
import filter from 'lodash.filter'
import remove from 'lodash.remove'

export default {
  name: 'Availabilities',

  components: {
    AddAvailabilityDialog,
    EditAvailabilityDialog
  },

  data () {
    return {
      availabilities: [],
      editAvailability: {},
      editDate: {},
      calendar: null,
      showAddDialog: false,
      showEditDialog: false
    }
  },

  mounted () {
    this.getAvailabilities()
  },

  computed: {
    collectionDates () {
      return this.formatDates('collection_dates', 'Collection')
    },

    returnDates () {
      return this.formatDates('return_dates', 'Return')
    },

    dates () {
        return [
        ...this.collectionDates,
        ...this.returnDates
      ]
    },

    newDates () {
      return remove(this.dates, date => {
        var removed = filter(this.removedDates, removeDate => {
          return (date.location.id === removeDate.location.id) &&
          (date.vehicle.id === removeDate.vehicle.id) &&
          (date.start === removeDate.start) &&
          (date.category === removeDate.category) &&
          date.type !== 'TYPE_UNAVAILABLE'
        })
        if (removed.length === 0) {
          return true
        }
      })
    },

    removedDates () {
      return filter(this.dates, date => {
        return date.type === 'TYPE_UNAVAILABLE'
      })
    }
  },

  methods: {
    handleEventClick (e) {
      this.showEditDialog = true
      this.editAvailability = e.event
      this.editDate = e.day
    },

    eventColor (event) {
      if (event.type === 'TYPE_UNAVAILABLE') {
        return 'rgb(255, 11, 11, 0.3)'
      }
      if (event.category === 'collection_dates') {
        return 'rgb(255, 148, 28, 0.3)'
      }
      return 'rgba(0, 160, 155, 0.3)'
    },

    formatDates (category, name) {
      var formatted = []
      this.availabilities.forEach(availability => {
        availability[category].forEach(date => {
          formatted.push({
            ...availability,
            ...{
              start: this.$moment(date).format('YYYY-MM-DD'),
              name: `${name}: ${availability.location.name} - ${availability.vehicle.name}`,
              category: category
            }
          })
        })
      })
      return formatted
    },

    getAvailabilities () {
      this.$api.get({
        path: 'availabilities',
        params: {
          limit: 1000 * 100,
          with: ['location', 'vehicle']
        }
      })
        .then(({ data: availabilities }) => {
          this.availabilities = availabilities
        })
    }
  }
}
</script>
